import { render, staticRenderFns } from "./tableDetail.vue?vue&type=template&id=babaf472&scoped=true&"
import script from "./tableDetail.vue?vue&type=script&lang=js&"
export * from "./tableDetail.vue?vue&type=script&lang=js&"
import style0 from "./tableDetail.vue?vue&type=style&index=0&id=babaf472&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "babaf472",
  null
  
)

export default component.exports