<!--
 * @Author: zx
 * @Date: 2022-08-26 10:31:52
 * @LastEditTime: 2022-12-30 15:23:05
 * @Description: 
 * @LastEditors: zx
-->
<template>
  <div class="table">
    <el-table
      ref="viewTable"
      :data="tableList"
      border
      center
      style="width: 100%;"
      height="100%"
      highlight-current-row
    >
      <el-table-column label="序号" type="index" width="120"></el-table-column>
      <el-table-column 
        :props="element.key"
        :label="element.label"
        v-for="(element, ind) in getKeyList()"
        :key="ind"
      >
        <template slot-scope="scope">
          <span v-if="scope.row[element.key] && scope.row[element.key].indexOf('http') === -1">{{ scope.row[element.key] }}</span>
          <el-image  v-if="scope.row[element.key] && scope.row[element.key].indexOf('http') !== -1" :src="scope.row[element.key]" :preview-src-list="[scope.row[element.key]]"></el-image>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="100">
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            @click="detailClick(scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 图片预览 -->
    <el-dialog
      :visible.sync="detailVisible"
      append-to-body
      lock-scroll
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      title="详情"
      width="40%"
    >
      <TableDetail
        v-if="detailVisible"
        :showLabelFields="showLabelFields"
        :detailInfo="detailInfo"
        :currentTypeObj="currentTypeObj"
      ></TableDetail>
    </el-dialog>
  </div>
</template>
<script>
import { Table, Image } from 'element-ui'
import TableDetail from '@/manage-views/views/application/myApplication/components/tableDetail.vue'
export default {
  components:{
    'el-table': Table,
    'el-image': Image,
    TableDetail
  },
  props:{
    currentTypeObj: {
      type: Object,
      default: () => {
        return {
          type: {},
          list: []
        }
      },
      required: true
    }
  },
  watch: {
    currentTypeObj: {
      handler(value) {
        this.tableList = value.list
      },
      immediate: true,
      deep: true
    }
  },
  data() {
    return {
      tableList: [], // 表格数据
      showLabelFields: [],
      coverField: '', // 封面图field
      titleField: '', // 标题field
      showTitle: false, // 显示标题
      previewImgVisibble: false, // 是否显示预览图片
      previewUrl: '', // 预览图片url
      detailInfo: null, // 详情数据
      detailVisible: false, // 详情弹窗是否显示
    }
  },
  mounted() {
    this.editList()
  },
  methods:{
    /* 根据传入数据，处理展示数据tableList */
    editList() {
      const cardSearchField = this.currentTypeObj?.tab?.view_config?.show?.cardField || []
      this.coverField = this.currentTypeObj?.tab?.view_config?.show?.cover || ''
      this.showTitle = this.currentTypeObj?.tab?.view_config?.show?.showTitle
      if (this.showTitle) {
        this.titleField = this.currentTypeObj?.tab?.view_config?.show?.title || ''
      }
      if (cardSearchField && cardSearchField.length) {
        this.showLabelFields = []
        cardSearchField.forEach(element => {
          if (element && element.show) {
            this.showLabelFields.push(element.field)
          }
        });
      }
      if (this.currentTypeObj.list && this.currentTypeObj.list.length) {
        this.tableList = this.currentTypeObj.list
      } else {
        this.tableList= []
      }
    },
    /* 获取显示字段 */
    getKeyList() {
      if (!this.tableList || !this.tableList.length) {
        return
      }
      let data = this.tableList[0]
      const arr = []
      for (const key in data) {
        if (Object.hasOwnProperty.call(data, key)) {
          if (this.showLabelFields && this.showLabelFields.length && this.showLabelFields.indexOf(key) !== -1) {
            let label = ''
            if (this.currentTypeObj.fields && this.currentTypeObj.fields.length) {
              this.currentTypeObj.fields.forEach(element => {
                if (element.field === key) {
                  label = element.field_note
                }
              });
            }
            arr.push({
              key: key,
              value: data[key],
              label: label
            })
          }
        }
      }
      return arr
    },
    detailClick(data) {
      this.detailInfo = data
      this.detailVisible = true
    }
  }
}
</script>
<style lang='less' scoped>
  .table {
    width: 100%;
    height: 100%;
    background-color: peru;
  }
</style>
