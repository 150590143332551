/*
 * @Author: zx
 * @Date: 2022-09-19 11:10:06
 * @LastEditTime: 2022-09-19 11:10:06
 * @Description: 我的应用
 * @LastEditors: zx
 */
import request from '@/apis/http/saasRequest.js'
// 登录/注册 获取验证码
export function getFormList(params) {
  return request({
    url: '/api/form4/view-data-list',
    method: 'get',
    params
  })
}