<!--
    @name: ShowViewProp
    @description：ShowViewProp
    @author: ZengWei
    @date: 2022-09-01 17:22
-->
<template>
  <div>
    <el-form ref="form" :model="form" label-width="80px" label-position="left">
      <el-form-item label="分组字段" v-if="viewerType === 'board'">
        <el-select v-model="form.groupBy" placeholder="请选择分组字段" style="width: 100%" @change="changeFormInfo">
          <el-option v-for="(item, index) in formItemList" :key="index" :label="item.field_note" :value="item.field"></el-option>
        </el-select>
      </el-form-item>
      <template v-if="['card','board'].includes(viewerType)">
        <el-form-item label="标题字段" style="margin-bottom: 5px">
          <el-switch class="switch-fr mt5" v-model="form.showTitle"  @change="changeFormInfo"></el-switch>
        </el-form-item>
        <el-form-item label="" label-width="0px" v-if="form.showTitle">
          <el-select v-model="form.title" placeholder="请选择标题字段" style="width: 100%" @change="changeFormInfo">
            <el-option v-for="(item, index) in formItemList" :key="index" :label="item.field_note" :value="item.field"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="封面字段" style="margin-bottom: 5px">
          <el-switch class="switch-fr mt5" v-model="form.showCover" @change="changeFormInfo"></el-switch>
        </el-form-item>
        <el-form-item label="" label-width="0px" v-if="form.showCover">
          <el-select v-model="form.cover" placeholder="请选择封面字段" style="width: 100%" @change="changeFormInfo">
            <el-option v-for="(item, index) in filterImgFields()" :key="index" :label="item.label" :value="item.field"></el-option>
          </el-select>
        </el-form-item>
      </template>

      <el-form-item label="搜索字段" style="margin-bottom: 5px">
        <div class="switch-fr">
          <span style="margin-right: 10px">显示字段名称</span>
          <el-switch v-model="form.showSearchLabel" @change="changeFormInfo"></el-switch>
        </div>
      </el-form-item>
      <el-form-item label="" label-width="0px">
        <el-popover
          placement="bottom"
          trigger="click">
          <el-button slot="reference" style="width: 100%" icon="el-icon-plus">添加搜索控件</el-button>
          <div style="width: 372px">
            <el-form ref="form" :model="form" label-width="80px" label-position="left">
              <el-form-item v-for="item in formItemList" :key="item.field" :label="item.field_note" :value="item.field" label-width="150px">
                <el-switch class="switch-fr mt5" v-model="item.search" @change="onCardSearchChange"></el-switch>
              </el-form-item>
            </el-form>
          </div>
        </el-popover>
        <draggable
          :list="form.cardSearchField"
          :animation="340"
          group="selectItem">
          <div class="card-field selectItem" v-for="(item,index) in form.cardSearchField" :key="item.field">
            <span><i class="el-icon-sort"></i> {{item.field_note}}</span>
            <span class="switch-fr" @click="delCardSearchField(index)"><i class="el-icon-delete"></i></span>
          </div>
        </draggable>
      </el-form-item>

      <el-form-item :label="labelText" style="margin-bottom: 5px">
        <div class="switch-fr" v-if="['card','board'].includes(viewerType)">
          <span style="margin-right: 10px">显示字段名称</span>
          <el-switch v-model="form.showLabel" @change="changeFormInfo"></el-switch>
        </div>
      </el-form-item>
      <el-form-item label="" label-width="0px">
        <el-popover
          placement="bottom"
          trigger="click">
          <el-button slot="reference" style="width: 100%" icon="el-icon-plus">添加展示字段</el-button>
          <div style="width: 372px">
            <el-form ref="form" :model="form" label-width="80px" label-position="left">
              <el-form-item v-for="item in formItemList" :key="item.field" :label="item.field_note" :value="item.field" label-width="150px">
                <el-switch class="switch-fr mt5" v-model="item.show" @change="onCardChange"></el-switch>
              </el-form-item>
            </el-form>
          </div>
        </el-popover>
        <draggable
          :list="form.cardField"
          :animation="340"
          group="selectItem">
          <div class="card-field selectItem" v-for="(item,index) in form.cardField" :key="item.field">
            <span><i class="el-icon-sort"></i> {{item.field_note}}</span>
            <span class="switch-fr" @click="delCardField(index)"><i class="el-icon-delete"></i></span>
          </div>
        </draggable>
      </el-form-item>

      <el-form-item label="数据排序">
        <el-select v-model="form.sortType" placeholder="请选择数据排序" style="width: 100%" @change="changeFormInfo">
          <el-option label="系统默认排序" :value="1"></el-option>
          <el-option label="自定义排序" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" label-width="0px" v-if="form.sortType === 2">
        <el-select v-model="form.sortField" placeholder="请选择表单字段" style="width: 72%;margin-right: 11px" @change="changeFormInfo">
          <el-option v-for="(item, index) in formItemList" :key="index" :label="item.field_note" :value="item.field"></el-option>
        </el-select>
        <el-select v-model="form.sort" style="width: 25%" @change="changeFormInfo">
          <el-option label="降序" :value="1"></el-option>
          <el-option label="升序" :value="2"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import {Popover} from "element-ui";
import {viewGetFormItem} from "@/custom-component/form/newParser/scripts/itemDataCopy";

export default {
  name: 'ShowViewProp',
  components: {
    draggable,
    'el-popover': Popover
  },
  props: {
    viewerType: {
      type: String,
      default: 'table'
    },
    formInfo: {
      type: Object,
      default: () => {}
    },
    editTabVisible: {
      type: Boolean,
      default: false
    },
    editData: {
      type: Object,
      default: () => {}
    },
  },
  computed:{
    labelText(){
      if(['card','board'].includes(this.viewerType)){
        return '卡片字段'
      }
      return '表格字段'
    }
  },
	watch: {
		formInfo: {
			handler(val) {
        if (val?.json?.fields) {
          const reduceData = viewGetFormItem(JSON.parse(JSON.stringify(val.json.fields)))
          this.formItemList = reduceData
        }
			},
			deep: true,
			immediate: true
		}
	},
  data() {
    return {
      formItemList: [],
      form:{
        groupBy: '',
        showTitle: true,
        title: '',
        showCover: true,
        cover: '',
        showSearchLabel: true,
        showLabel: true,
        cardSearchField: [],
        cardField: [],
        sortType: 2,
        sortField: '',
        sort: 1,
      }
    }
  },
  created() {
    if (this.editTabVisible) {
      this.form = this.editData.view_config.show
      let searchField = Array.from(this.form.cardSearchField,item => item.field)
      let columnField = Array.from(this.form.cardField,item => item.field)
      for (let item of this.formItemList){
        if(searchField.includes(item.field)){
          item.search = true
        }
        if(columnField.includes(item.field)){
          item.show = true
        }
      }
    }
  },
  methods: {
    onCardChange(){
      this.form.cardField = this.formItemList.filter(item=>item.show)
      this.changeFormInfo()
    },
    onCardSearchChange(){
      this.form.cardSearchField = this.formItemList.filter(item=>item.search)
      console.log(this.form.cardSearchField, 'this.form.cardSearchField')
      this.changeFormInfo()
    },
    delCardField(index){
      this.form.cardField.splice(index,1)
      this.changeFormInfo()
    },
    delCardSearchField(index){
      this.form.cardSearchField.splice(index,1)
      this.changeFormInfo()
    },
    changeFormInfo() {
      this.$emit('changeFormInfo', this.form)
    },
    /* 封面过滤非图片字段 */
    filterImgFields() {
      let arr = []
      if (this.formItemList && this.formItemList.length) {
        this.formItemList.forEach(element => {
          if (element.element.type && element.element.type === 'upload') {
            arr.push(element)
          }
        });
      }
      return arr
    }
  },
}
</script>

<style lang="less" scoped>
.switch-fr{
  float: right;
}
.mt5{
  margin-top: 5px;
}

.card-field{
  margin-top: 12px;
  padding: 1px 15px;
  border: 1px solid #eeeeee;
  border-radius: 5px;
}
</style>
