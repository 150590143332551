<!--
    @name: EditViewer
    @description：EditViewer
    @author: ZengWei
    @date: 2022-09-01 16:00
-->
<template>
  <div v-loading="loading" class="edit-viewer">
    <div class="header">
      <div class="back">
        <h3 class="title" @click="close(false)">
          <i class="el-icon-arrow-left"></i>
          <span style="font-weight: normal;font-size: 16px">{{headTitle}}</span>
        </h3>
      </div>
      <div class="action">
        <el-button type="text" @click="showHidePanel">
          <i class="el-icon-edit-outline size24"></i>
        </el-button>
        <el-button type="text">
          <i class="el-icon-close size24" @click="close(false)"></i>
        </el-button>
      </div>
    </div>
    <div class="container">
      <div class="left-wrap" v-show="panelShow">
        <div class="inner-wrap">
          <div class="view-config-header">
            <span>视图配置</span>
            <i class="el-icon-close size22" @click="showHidePanel"></i>
          </div>
          <div class="view-config-content">
            <ul class="view-config-nav">
              <li
                class="nav-item"
                v-for="item in navData"
                :key="item.id"
                :class="{'nav-active': activeId === item.id}"
                @click="activeId=item.id">
                <i :class="item.icon"></i>
                <span>{{item.name}}</span>
              </li>
            </ul>
            <div class="view-config-widget">
              <BasicViewProp
                class="pr-16"
                v-if="activeId === 1"
                :formInfo="formInfo"
                :editData="editData"
                :editTabVisible="editTabVisible"
                @changeFormInfo="changeFormInfoBasic"
              ></BasicViewProp>
              <ShowViewProp
                class="pr-16"
                v-if="activeId === 2"
                :formInfo="formInfo"
                :editData="editData"
                :editTabVisible="editTabVisible"
                :viewerType="viewerType"
                @changeFormInfo="changeFormInfoShow"
              ></ShowViewProp>
              <FilterViewProp
                v-if="activeId === 3"
                :formInfo="formInfo"
                :editData="editData"
                :editTabVisible="editTabVisible"
                @changeFormInfo="changeFormInfoFilter"
              ></FilterViewProp>
              <PermsViewProp
                class="pr-16"
                v-if="activeId === 4"
                :formInfo="formInfo"
                :editData="editData"
                :editTabVisible="editTabVisible"
                @changeFormInfo="changeFormInfoPerms"
              ></PermsViewProp>
            </div>
          </div>
          <div class="view-config-footer">
            <el-button type="primary" icon="el-icon-finished" @click="submit">保存视图配置</el-button>
            <el-button :disabled="getListLoading" type="default" icon="el-icon-view" @click="showPriview">数据预览</el-button>
          </div>
        </div>
      </div>
      <div class="right-wrap">
        <TableList v-loading="getListLoading"
          v-if="showPriviewVisible &&
          currentTypeObj &&
          currentTypeObj.tab &&
          currentTypeObj.tab.type === 'table'"
          :currentTypeObj="currentTypeObj"
        />
        <CardList
          v-loading="getListLoading"
          v-if="showPriviewVisible &&
            currentTypeObj &&
            currentTypeObj.tab &&
            currentTypeObj.tab.type === 'card'"
          :currentTypeObj="currentTypeObj"
        />
        <BoardList v-loading="getListLoading"
        v-if="showPriviewVisible &&
          currentTypeObj &&
          currentTypeObj.tab &&
          currentTypeObj.tab.type === 'board'"
        :currentTypeObj="currentTypeObj" />
      </div>
    </div>
  </div>
</template>

<script>
import BasicViewProp from "@/manage-views/views/application/myApplication/components/BasicViewProp";
import ShowViewProp from "@/manage-views/views/application/myApplication/components/ShowViewProp";
import FilterViewProp from "@/manage-views/views/application/myApplication/components/FilterViewProp";
import PermsViewProp from "@/manage-views/views/application/myApplication/components/PermsViewProp";
import TableList from './components/tableList';
import CardList from "./components/cardList.vue";
import BoardList from "./components/boardList.vue";
import { formDesign } from "@/api/home.js";
import { mapiRequest } from '@/apis/http/saasMapiRequest'
import { getFormList } from '@/api/application'

export default {
  name: 'EditViewer',
  components: {
    BasicViewProp,
    ShowViewProp,
    FilterViewProp,
    PermsViewProp,
    TableList,
    CardList,
    BoardList,
  },
  props: {
    viewerType: {
      type: String,
      default: 'table'
    },
    formUuid: {
      type: String,
      default: ''
    },
    editTabVisible: {
      type: Boolean,
      default: false
    },
    editData: {
      type: Object,
      default: () => {}
    },
    typeObj: {
      type: Object,
      default: () => {}
    },
  },
  data() {
    return {
      panelShow: true,
      navData:[
        {id:1,name:'基础信息',icon:'el-icon-warning-outline'},
        {id:2,name:'数据展示',icon:'el-icon-data-analysis'},
        {id:3,name:'数据筛选',icon:'el-icon-s-operation'},
        {id:4,name:'操作权限',icon:'el-icon-lock'},
      ],
      activeId: 1,
      formInfo: {},
      submitFormData: {},
      loading: false,
      pager: {
				page: 1,
				size: 15,
				total: 0
			},
      getListLoading: false,
      currentTypeObj: {},
      showPriviewVisible: true
    }
  },
  computed: {
    headTitle(){
      if (this.editTabVisible) {
        return '编辑' +this.editData.name
      } else {
        const dict = {
          'table': '表格视图',
          'card': '卡片视图',
          'board': '看板视图',
        }
        return dict[this.viewerType]
      }
    },
  },
  created() {
    if (this.editTabVisible) {
      this.submitFormData = this.editData.view_config
    }
    this.getFormInfo()
    this.getList()
  },
  methods: {
    getFormInfo() {
      if (this.formUuid) {
        formDesign.formShow(this.formUuid).then((res) => {
          if( res.status === 200 && res.data.code === 200) {
            const data = res.data.data
            data.json = JSON.parse(data.json)
            this.formInfo = data
          }
        })
      } else {
        this.$message.error('表单id缺失！')
      }
    },
    close(boo){
      this.$emit('close', boo ? boo : false)
    },
    showHidePanel(){
      this.panelShow = !this.panelShow
    },
    changeFormInfoBasic(data) {
      this.submitFormData.basic = data
    },
    changeFormInfoShow(data) {
      this.submitFormData.show = data
    },
    changeFormInfoFilter(data) {
      this.submitFormData.filter = data
    },
    changeFormInfoPerms(data) {
      this.submitFormData.perms = data
    },
    getTypeNum(str) {
      if (str === 'table') {
        return 1
      } else if (str === 'card') {
        return 2
      } else {
        return 3
      }
    },
    submit() {
      if (!this.submitFormData.basic || !this.submitFormData.basic.name) {
        return this.$message.error('未完成参数填写！')
      }
      const params = {
        view_config: JSON.stringify(this.submitFormData),
        name: this.submitFormData.basic.name,
        is_head: 1,
        form_module_uuid: this.formUuid,
        view_show_type: this.getTypeNum(this.viewerType),
				__method_name__: 'createData',
				object_uuid: 'object63204356e9ed4',
				view_uuid: 'view632051f6a290f',
				transcode: 0,
      }
      if (this.editTabVisible) {
        params.__method_name__ = 'updateData'
        params.data_id = this.editData.id
      }
      this.loading = true
      mapiRequest(params).then((res) => {
        this.loading = false
        if (res.status === 200 && res.data.code === 200) {
          this.close(true)
        }
      })
    },
    showPriview() {
      let tab = JSON.parse(JSON.stringify(this.typeObj.tab))
      tab.view_config = JSON.parse(JSON.stringify(this.submitFormData))
      this.$set(this.currentTypeObj, 'tab', tab)
      this.currentTypeObj = {
        tab: tab,
        list: [],
        fields: []
      }
      this.getList()
    },
    getList() {
      const params = {
        viewUuid: this.typeObj?.tab?.id,
        page: this.pager.page,
        size: this.pager.size,
      }
      this.getListLoading = true
      this.currentTypeObj.list = []
      if(this.typeObj?.tab?.id) {
        getFormList(params).then((res) => {
          this.getListLoading = false
          if (res.status === 200  && res.data.code === 200) {
            this.pager.total = res.data.data.total
            this.pager.page = res.data.data.current_page
            let obj = this.currentTypeObj
            obj.list = res.data.data.data
            obj.fields = res.data.data.fields
            this.$set(this.currentTypeObj, 'list', res.data.data.data)
            this.$set(this.currentTypeObj, 'fields', res.data.data.fields)
            this.currentTypeObj = obj
            this.showPriviewVisible = false
            this.$nextTick(() => {
              this.showPriviewVisible = true
            })
            console.log(this.currentTypeObj, 'this.currentTypeObj')
          }
        }).catch(() => {
          this.getListLoading = false
        })
      }
    },
  },
}
</script>

<style lang="less" scoped>
.edit-viewer{
  display: flex;
  flex-direction: column;
  height: 100%;

  .size24{
    font-size: 20px;
    color: #000;
  }
  .size22{
    font-size: 22px;
    color: #000;
  }

  .pr-16{
    padding-right: 16px;
  }

  .header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 52px;
    padding: 4px 8px;
    border-bottom: solid thin #E6EAF0;
    background-color: #fff;
    .rotateY{
      transform: rotate(90deg);
    }
  }
  .container{
    height: calc(100vh - 54px);
    display: flex;
    background-color: #F9FAFC;

    .left-wrap{
      width: 600px;
      height: 100%;
      .inner-wrap{
        width: 100%;
        height: 100%;
        border-right: 1px solid #E6EAF0;
        border-top: none;
        background-color: #fff;

        .view-config-header{
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 16px;
          font-size: 20px;
          font-weight: 600;
          line-height: 35px;
          color: #000;
          border-bottom: 1px solid #E6EAF0;
        }

        .view-config-content{
          display: flex;
          height: calc(100% - 160px);
          padding: 8px 0 16px 16px;

          .view-config-nav{
            width: 156px;
            height: 100%;
            border-right: 1px solid #E6EAF0;
            overflow: hidden;

            .nav-item{
              display: flex;
              flex-direction: row;
              align-items: center;
              width: 110px;
              padding: 6px 6px 6px 12px;
              margin: 10px;
              border-radius: 8px;
              font-size: 16px;
              font-weight: 400;
              line-height: 24px;
              cursor: pointer;
              i {
                margin-right: 8px;
              }
              &:hover{
                background-color: #f4f6f9;
              }
            }
            .nav-active{
              font-weight: 600;
              background-color: #f4f6f9;
            }
          }

          .view-config-widget{
            padding-top: 15px;
            width: calc(100% - 162px);
            height: 100%;
            margin-left: 16px;
            overflow: auto;
          }
        }

        .view-config-footer{
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-end;
          height: 45px;
          padding: 10px;
          border-top: 1px solid #E6EAF0;
        }

      }
    }

    .right-wrap{
      flex: 1;
      margin: 15px;
      background-color: #FFFFFF;
    }
  }
}
</style>
