<!--
 * @Author: zx
 * @Date: 2022-08-26 10:31:52
 * @LastEditTime: 2022-12-30 15:09:16
 * @Description: 
 * @LastEditors: zx
-->
<template>
  <div class="detail">
		<el-form
			:model="detailInfo"
			v-if="detailInfo"
		>
			<el-form-item 
        v-for="(item, index) in formArr"
        :key="index"
        :label="item.label"
      >
        <div class="form-item-container">
          <span v-if="item.value.indexOf('http') ===  -1">{{item.value}}</span>
          <img  v-else :src="item.value" :alt="item.label" />
        </div>
			</el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  name: 'tableDetail',
  props:{
    detailInfo: {
      type: Object,
      default: () => {}
    },
    showLabelFields: {
      type: Array,
      default: () => []
    },
    currentTypeObj: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      formArr: []
    }
  },
  mounted() {
    console.log(this.detailInfo, 'detailInfo')
    this.getKeyList()
  },
  methods:{
    /* 获取表单显示字段 */
    getKeyList() {
      if (!this.detailInfo) {
        return
      }
      let data = this.detailInfo
      const arr = []
      for (const key in data) {
        if (Object.hasOwnProperty.call(data, key)) {
          if (this.showLabelFields && this.showLabelFields.length && this.showLabelFields.indexOf(key) !== -1) {
            let label = ''
            if (this.currentTypeObj.fields && this.currentTypeObj.fields.length) {
              this.currentTypeObj.fields.forEach(element => {
                if (element.field === key) {
                  label = element.field_note
                }
              });
            }
            arr.push({
              key: key,
              value: data[key],
              label: label
            })
          }
        }
      }
      this.formArr =  arr
    },
  }
}
</script>
<style lang='less' scoped>
  .detail {
    height: 700px;
    max-height: 700px;
    overflow-y: auto;
    .form-item-container {
      img {
        width: 100%;
        height: auto;
      }
    }
  }
</style>
