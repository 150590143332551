<!--
 * @Author: zx
 * @Date: 2022-08-26 10:31:52
 * @LastEditTime: 2022-12-30 15:07:38
 * @Description:
 * @LastEditors: zx
-->
<template>
  <div class="card">
    <div class="card-item" v-for="(item, index) in cardList" :key="'card' + index">
      <div class="card-item-row">
        <div class="card-content-img">
          <el-image v-if="item[coverField]" :src="item[coverField]" :preview-src-list="[item[coverField]]"></el-image>
        </div>
        <div v-if="showTitle" class="card-content-title">
          <el-image  v-if="titleField && item[titleField] && item[titleField].indexOf('http') !== -1" :src="item[titleField]" :preview-src-list="[item[titleField]]"></el-image>
          <p v-else>{{ item[titleField] }}</p>
        </div>
        <div
          class="card-content-key"
          v-for="(element, ind) in getKeyList(item)"
          :key="ind"
        >
          <div class="card-field-container">
            <p v-if="showSearchLabel">{{ element.label }}:</p>
            <span>{{ element.value }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {Image} from "element-ui";
export default {
  components:{
    'el-image': Image
  },
  props:{
    currentTypeObj: {
      type: Object,
      default: () => {
        return {
          type: 'card',
          list: []
        }
      },
      required: true
    }
  },
  data() {
    return {
      cardList: [], // 显示的数组数据
      showLabelFields: [],
      coverField: '', // 封面图field
      titleField: '', // 标题field
      showTitle: false, // 显示标题
      showSearchLabel: false, // 显示字段名
      previewImgVisibble: false,
      previewUrl: '',
    }
  },
  watch: {
    currentTypeObj: {
      handler() {
        this.editList()
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {
    this.editList()
  },
  methods:{
    /* 传入类型及list数据处理 */
    editList() {
      const cardSearchField = this.currentTypeObj?.tab?.view_config?.show?.cardField || []
      this.coverField = this.currentTypeObj?.tab?.view_config?.show?.cover || ''
      this.showTitle = this.currentTypeObj?.tab?.view_config?.show?.showTitle
      this.showSearchLabel = this.currentTypeObj?.tab?.view_config?.show?.showSearchLabel || false
      if (this.showTitle) {
        this.titleField = this.currentTypeObj?.tab?.view_config?.show?.title || ''
      }
      if (!this.coverField) {
        this.$message.error('封面图配置错误！')
      }
      if (cardSearchField && cardSearchField.length) {
        this.showLabelFields = []
        cardSearchField.forEach(element => {
          if (element && element.show) {
            this.showLabelFields.push(element.field)
          }
        });
      }
      if (this.currentTypeObj.list && this.currentTypeObj.list.length) {
        this.cardList = this.currentTypeObj.list
      } else {
        this.cardList= []
      }
    },
    /* 过滤获取显示字段 */
    getKeyList(data) {
      const arr = []
      for (const key in data) {
        if (Object.hasOwnProperty.call(data, key)) {
          if (this.showLabelFields && this.showLabelFields.length && this.showLabelFields.indexOf(key) !== -1) {
            let label = ''
            if (this.currentTypeObj.fields && this.currentTypeObj.fields.length) {
              this.currentTypeObj.fields.forEach(element => {
                if (element.field === key) {
                  label = element.field_note
                }
              });
            }
            arr.push({
              key: key,
              value: data[key],
              label: label
            })
          }
        }
      }
      return arr
    },
    /* 预览图片 */
    previewImg(url) {
      if (url && url.indexOf('http') !== -1) {
        this.previewUrl = url
        this.previewImgVisibble = true
      }
    }
  }
}
</script>
<style lang='less' scoped>
  .card {
    width: 100%;
    height: 100%;
    background-color: #f2f4f5;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    overflow-y: auto;
    .card-item {
      width: 16%;
      height: auto;
      background-color: #fff;
      box-shadow: 0 4px 6px #0c1f500a, 0 0 0 1px #e6eaf0;
      border-radius: 4px;
      padding: 10px;
      box-sizing: border-box;
      margin: 16px;
      .card-item-row {
        .card-content-img {
          width: 100%;
          height:  200px;
          border-radius: 16px;
          overflow: hidden;
          img {
            width: 100%;
            height:  100%;
            object-fit: cover;
          }
        }
        .card-content-title {
          line-height: 30px;
          p {
            font-weight: 600;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          img {
            width: 30px;
            height: 30px;
          }
        }
        .card-content-key {
          padding: 4px 0;
          text-align: left;
          .card-field-container {
            display: flex;
            p {
              width: auto;
              margin-right: 6px;
            }
            span:nth-child(2) {
              flex: 1;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
</style>
