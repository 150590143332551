<!--
 * @Author: zx
 * @Date: 2022-08-26 10:31:52
 * @LastEditTime: 2022-12-30 15:06:59
 * @Description:
 * @LastEditors: zx
-->
<template>
  <div class="board">
    <div class="board-content" v-for="(item, index) in boardList" :key="index">
      <p class="board-content-p" v-if="item.type">{{ item.type }}</p>
      <div
        class="board-content-item"
        v-for="(item, index) in item.arr"
        :key="index"
      >
        <div class="board-content-img">
          <img v-if="item[coverField]" :src="item[coverField]" alt="" @click="previewImg(item[coverField])"  />
        </div>
        <div v-if="showTitle" class="board-content-title">
          <el-image  v-if="titleField && item[titleField] && item[titleField].indexOf('http') !== -1" :src="item[titleField]" :preview-src-list="[item[titleField]]"></el-image>
          <p v-else>{{ item[titleField] }}</p>
        </div>
        <div
          class="board-content-key"
          v-for="(element, ind) in getKeyList(item)"
          :key="ind"
        >
          <div class="card-field-container">
            <p v-if="showSearchLabel">{{ element.label }}:</p>
            <span>{{ element.value }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {Image} from "element-ui";
export default {
  components:{
    'el-image': Image
  },
  props:{
    currentTypeObj: {
      type: Object,
      default: () => {
        return {
          type: 'board',
          list: []
        }
      },
      required: true
    }
  },
  data() {
    return {
      boardList: [], // 卡片列表数据
      showLabelFields: [], // 根据配置，现实的字段数组
      coverField: '', // 封面图field
      titleField: '', // 标题field
      showTitle: false,
      showSearchLabel: false,
      previewImgVisibble: false, // 是否展示图片预览
      previewUrl: '', // 预览图片地址
    }
  },
  watch: {
    currentTypeObj: {
      handler() {
        this.editList()
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {
    this.editList()
  },
  methods:{
    /* 传入类型及list数据处理 */
    editList() {
      const cardSearchField = this.currentTypeObj?.tab?.view_config?.show?.cardField || []
      this.coverField = this.currentTypeObj?.tab?.view_config?.show?.cover || ''
      this.showTitle = this.currentTypeObj?.tab?.view_config?.show?.showTitle
      this.showSearchLabel = this.currentTypeObj?.tab?.view_config?.show?.showSearchLabel || false
      this.groupField = this.currentTypeObj?.tab?.view_config?.show?.groupBy || ''
      if (this.showTitle) {
        this.titleField = this.currentTypeObj?.tab?.view_config?.show?.title || ''
      }
      if (!this.coverField) {
        this.$message.error('封面图配置错误！')
      }
      if (cardSearchField && cardSearchField.length) {
        this.showLabelFields = []
        cardSearchField.forEach(element => {
          if (element && element.show) {
            this.showLabelFields.push(element.field)
          }
        });
      }
      if (this.groupField && this.currentTypeObj.list) {
        const arr = []
        this.currentTypeObj.list.forEach(element => {
          if (element[this.groupField]) {
            if (arr.length) {
              let index = null
              arr.forEach((item, ind) => {
                if (item.type === element[this.groupField]) {
                  index = ind
                }
              });
              if (index === 0 || index) {
                arr[index].arr.push(element)
              } else {
                arr.push({ type: element[this.groupField], arr: [element] })
              }
            } else {
              arr[0] = { type: element[this.groupField], arr: [element] }
            }
          }
        });
        this.boardList = arr
      } else if (!this.groupField && this.currentTypeObj.list && this.currentTypeObj.list.length) {
        this.boardList = [{type: '未分组', arr: this.currentTypeObj.list}]
      }
    },
    /* 过滤获取显示字段 */
    getKeyList(data) {
      const arr = []
      for (const key in data) {
        if (Object.hasOwnProperty.call(data, key)) {
          if (this.showLabelFields && this.showLabelFields.length && this.showLabelFields.indexOf(key) !== -1) {
            let label = ''
            if (this.currentTypeObj.fields && this.currentTypeObj.fields.length) {
              this.currentTypeObj.fields.forEach(element => {
                if (element.field === key) {
                  label = element.field_note
                }
              });
            }
            arr.push({
              key: key,
              value: data[key],
              label: label
            })
          }
        }
      }
      return arr
    },
    /* 预览图片 */
    previewImg(url) {
      if (url && url.indexOf('http') !== -1) {
        this.previewUrl = url
        this.previewImgVisibble = true
      }
    }
  }
}
</script>
<style lang='less' scoped>
  .board {
    width: 100%;
    flex: 1;
    overflow: hidden;
    background-color: #F9FAFC;
    overflow-x: auto;
    white-space: nowrap;
    .board-content {
      display: inline-block;
      width: 300px;
      height: 100%;
      overflow: auto;
      box-sizing: border-box;
      padding: 10px;
      background-color: #F4F6F9;
      border: 1px solid #eee;
      margin-right: 10px;
      .board-content-p {
        font-weight: 600;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .board-content-item {
        width: 100%;
        height: auto;
        margin-top: 8px;
        padding: 10px;
        background-color: #fff;
        box-sizing: border-box;
        // 封面图
        .board-content-img {
          width: 100%;
          height:  200px;
          border-radius: 16px;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .board-content-title {
          line-height: 30px;
          p {
            font-weight: 600;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          img {
            width: 30px;
            height: 30px;
          }
        }
        .board-content-key {
          padding: 4px 0;
          text-align: left;
          .card-field-container {
            display: flex;
            p {
              width: auto;
              margin-right: 6px;
            }
            span:nth-child(2) {
              flex: 1;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }
    }

  }
</style>
