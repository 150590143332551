<!--
 * @Author: zx
 * @Date: 2022-09-08 16:38:14
 * @LastEditTime: 2022-09-14 14:58:08
 * @Description:
 * @LastEditors: zx
-->
<!--
    @name: BasicViewProp
    @description：BasicViewProp
    @author: ZengWei
    @date: 2022-09-01 17:22
-->
<template>
  <div>
    <el-form ref="form" :model="form" label-width="80px" label-position="left">
      <el-form-item label="视图标题">
        <el-input v-model="form.name" @change="changeFormInfo"></el-input>
      </el-form-item>
      <el-form-item label="可见范围">
        <el-select v-model="form.scope" placeholder="请选择可见范围" style="width: 100%" @change="changeFormInfo">
          <el-option label="工作区可见" :value="1"></el-option>
          <el-option label="指定成员可见" :value="2"></el-option>
          <el-option label="所有人可见" :value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="密码访问">
        <el-switch v-model="form.passport" @change="changeFormInfo"></el-switch>
      </el-form-item>
      <el-form-item label="密码设置" v-if="form.passport">
        <el-input type="password" v-model="form.password" @change="changeFormInfo"></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'BasicViewProp',
  components: {},
  props: {
    editTabVisible: {
      type: Boolean,
      default: false
    },
    editData: {
      type: Object,
      default: () => {}
    },
  },
  data() {
    return {
      form:{
        name: '',
        scope: '',
        passport: false,
        password: '',
      },
    }
  },
  created() {
    if (this.editTabVisible) {
      if(this.editData.view_config?.basic){
        this.form = this.editData.view_config.basic || {}
      }
    }
  },
  methods: {
    changeFormInfo() {
      this.$emit('changeFormInfo', this.form)
    }
  },
}
</script>

<style scoped>

</style>
