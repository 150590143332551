<!--
    @name: PersViewProp
    @description：PersViewProp
    @author: ZengWei
    @date: 2022-09-01 17:23
-->
<template>
  <div>
    <el-form ref="form" label-width="80px" label-position="left">
      <el-form-item label="表头按钮">
        <div v-for="(item,index) in tableBtn" :key="index" style="margin-top: 10px">
          <el-select v-model="item.method" placeholder="请选择按钮类型" style="width: 40%;margin-right: 12px" @change="changeFormInfo">
            <el-option v-for="eleItem in headBtn" :key="eleItem.method" :label="eleItem.name" :value="eleItem.method"></el-option>
          </el-select>
          <el-input v-model="item.name" style="width: 49%" @change="changeFormInfo"></el-input>
          <i class="el-icon-delete" @click="delButton(index)"></i>
        </div>
        <div style="margin-top: 15px">
          <el-button type="default" icon="el-icon-plus" style="width: 100%" @click="addButton">增加表头按钮</el-button>
        </div>
      </el-form-item>
      <el-form-item label="数据操作">
        <div v-for="(item,index) in inlineBtn" :key="index" style="margin-top: 10px">
          <el-select v-model="item.method" placeholder="请选择操作" style="width: 40%;margin-right: 12px" @change="changeFormInfo">
            <el-option v-for="eleItem in dataBtn" :key="eleItem.method" :label="eleItem.name" :value="eleItem.method"></el-option>
          </el-select>
          <el-input v-model="item.name" style="width: 49%" @change="changeFormInfo"></el-input>
          <i class="el-icon-delete" @click="delMethod(index)"></i>
        </div>
        <div style="margin-top: 15px">
          <el-button type="default" icon="el-icon-plus" style="width: 100%" @click="addMethod">增加数据操作</el-button>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'PermsViewProp',
  components: {},
  props: {
    editTabVisible: {
      type: Boolean,
      default: false
    },
    editData: {
      type: Object,
      default: () => {}
    },
  },
  data() {
    return {
      headBtn:[
        {name:'导入',method:'import'},
        {name:'导出',method:'export'},
        {name:'新增',method:'add'},
      ],
      dataBtn:[
        {name:'详情',method:'detail'},
        {name:'编辑',method:'edit'},
        {name:'删除',method:'delete'},
        {name:'导出',method:'export'},
      ],
      tableBtn:[
        {name:'新增',method:'add'},
      ],
      inlineBtn: [
        {name:'详情',method:'detail'},
      ],
    }
  },
  created() {
    if (this.editTabVisible) {
      if(this.editData.view_config?.perms?.tableBtn){
        this.tableBtn = this.editData.view_config.perms.tableBtn || []
      }
      if(this.editData.view_config?.perms?.inlineBtn){
        this.inlineBtn = this.editData.view_config?.perms?.inlineBtn || []
      }
    }
  },
  methods: {
    changeFormInfo() {
      let form = {}
      form.tableBtn = this.tableBtn
      form.inlineBtn = this.inlineBtn
      this.$emit('changeFormInfo', form)
    },
    addButton() {
      this.tableBtn.push({name:'新增',method:'add'})
      this.changeFormInfo()
    },
    delButton(index) {
      this.tableBtn.splice(index, 1)
      this.changeFormInfo()
    },
    addMethod() {
      this.inlineBtn.push({name:'详情',method:'detail'})
      this.changeFormInfo()
    },
    delMethod(index) {
      this.inlineBtn.splice(index, 1)
      this.changeFormInfo()
    },
  },
}
</script>

<style scoped>
.el-icon-delete{
  margin-left: 8px;
  cursor: pointer;
}
</style>
