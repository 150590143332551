<!--
    @name: FilterViewProp
    @description：FilterViewProp
    @author: ZengWei
    @date: 2022-09-01 17:23
-->
<template>
  <div>
    <div style="padding-right: 15px">
      <el-form ref="form" :model="form" label-width="80px" label-position="left">
        <el-form-item label="筛选配置">
          <el-button type="default" icon="el-icon-plus" @click="openDialog" style="width: 100%">设置筛选条件</el-button>
        </el-form-item>
        <h3 class="sub-title">字段权限</h3>
        <el-form-item v-for="eleItem in formItemList" :key="eleItem.field" :label="eleItem.field_note" label-width="200px">
          <el-radio-group v-model="eleItem.permission" @change="changeFormInfo">
            <el-radio-button :label="1">可编辑</el-radio-button>
            <el-radio-button :label="2">仅可见</el-radio-button>
            <el-radio-button :label="3">隐藏</el-radio-button>
          </el-radio-group>
        </el-form-item>
      </el-form>
    </div>

    <el-dialog
      title="筛选数据"
      top="5vh"
      :visible.sync="dialogVisible"
      append-to-body
      width="1000px">
      <div class="article-main" v-if="dialogVisible">
        <el-form ref="form" label-width="80px" label-position="left">
          <el-form-item label="" label-width="0px">
            <div></div>
            <div v-for="(eleItem,eleIndex) in conditionGroup" :key="eleIndex" class="content">
              <i class="el-icon-close" @click="delOrCondition(eleIndex)"></i>
              <div class="wrapper">
                <el-row :gutter="15" v-for="(item,index) in eleItem.condition" :key="index" class="condition-group">
                  <el-col :span="8">
                    <el-select class="f-width" v-model="item.field" placeholder="请选择字段" @change="chooseField(item)">
                      <el-option
                        v-for="eleItem in formItemList"
                        :key="eleItem.field"
                        :label="eleItem.field_note"
                        :value="eleItem.field">
                      </el-option>
                    </el-select>
                  </el-col>
                  <el-col :span="8">
                    <el-select class="f-width" v-model="item.method" placeholder="请选择操作">
                      <el-option
                        v-for="eleItem in judgeList"
                        :key="eleItem.value"
                        :label="eleItem.name"
                        :value="eleItem.value">
                      </el-option>
                    </el-select>
                  </el-col>
                  <el-col :span="8">
                    <template v-if="item.element">
                      <input-item class="f-width" :element="item.element" :key="index"></input-item>
                    </template>
                    <el-input v-else class="f-width" v-model="item.value"></el-input>
                  </el-col>
                  <i class="el-icon-delete" @click="delAndCondition(eleItem,index)"></i>
                </el-row>
                <div style="margin-top: 15px">
                  <el-button type="default" icon="el-icon-plus" @click="addAndCondition(eleItem)">且条件</el-button>
                </div>
              </div>
              <p v-if="(conditionGroup.length - 1) !== eleIndex" style="margin-bottom: 10px">或</p>
            </div>
            <el-button type="default" icon="el-icon-plus" @click="addOrCondition">或条件</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false;changeFormInfo()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {RadioGroup, RadioButton} from "element-ui";
import InputItem from '@/custom-component/form/newParser/InputItem';
import {viewGetFormItem} from "@/custom-component/form/newParser/scripts/itemDataCopy";

export default {
  name: 'FilterViewProp',
  components: {
    InputItem,
    'el-radio-group': RadioGroup,
    'el-radio-button': RadioButton,
  },
  props: {
    formInfo: {
      type: Object,
      default: () => {}
    },
    editTabVisible: {
      type: Boolean,
      default: false
    },
    editData: {
      type: Object,
      default: () => {}
    },
  },
  data() {
    return {
      dialogVisible: false,
      form:{},
      judgeList: [
        { name: "等于", value: "eq" },
        { name: "大于", value: "gt" },
        { name: "小于", value: "lt" },
        { name: "不等于", value: "neq" },
        { name: "大于等于", value: "egt" },
        { name: "小于等于", value: "elt" },
        { name: "模糊包含", value: "like" },
        { name: "包含任意", value: "in" },
        { name: "不包含", value: "notin" },
        { name: "介于之间", value: "between" },
      ],
      conditionGroup:[
        {
          condition:[
            {formId:'',field:'',judge:'',value:'',element:''}
          ]
        }
      ],
      formItemList:[],
    }
  },
	watch: {
		formInfo: {
			handler(val) {
        if (val?.json.fields) {
          const reduceData = viewGetFormItem(JSON.parse(JSON.stringify(val.json.fields)))
          for (let item of reduceData){
            item.permission = 1
          }
          this.formItemList = reduceData
        }
			},
			deep: true,
			immediate: true
		}
	},
  created() {
    if (this.editTabVisible) {
      if(this.editData.view_config?.filter?.conditionGroup){
        this.conditionGroup = this.editData.view_config?.filter?.conditionGroup || []
      }
      if(this.editData.view_config?.filter?.permission){
        this.form.permission = this.editData.view_config?.filter?.permission || []
      }
    }
  },
  methods: {
    openDialog(){
      this.dialogVisible = true
    },
    addAndCondition(eleItem){
      const condition = {formId:'',field:'',judge:'',value:'',element:''}
      eleItem.condition.push(condition)
    },
    delAndCondition(eleItem,index){
      eleItem.condition.splice(index,1)
    },
    addOrCondition(){
      const group = {condition: [{formId:'',field:'',judge:'',value:'',element:''}]}
      this.conditionGroup.push(group)
    },
    delOrCondition(index){
      this.conditionGroup.splice(index,1)
    },
    changeFormInfo() {
      const permissions = []
      for (let item of this.formItemList){
        permissions.push({
          formId: item.field,
          permission: item.permission,
        })
      }
      for (let item of this.conditionGroup){
        for (let eleItem of item.condition){
          if(eleItem.element){
            eleItem.value = eleItem.element.value
          }
        }
      }
      this.form.permissions = permissions
      this.form.conditionGroup = this.conditionGroup
      this.$emit('changeFormInfo', this.form)
    },
    /* 选择字段触发name */
    chooseField(data) {
      this.formItemList.forEach((item) => {
        if (data.field === item.element.config.__config__.formId) {
          data.formId = item.element.config.__config__.formId
          data.field = item.element.config.__vModel__
          data.element = item.element
        }
      })
    }
  },
}
</script>

<style lang="less" scoped>
.sub-title{
  margin-bottom: 15px;
}

.f-width{
  width: 100%;

  :deep(.el-select){
    width: 100%;
  }
  :deep(.el-cascader){
    width: 100%;
  }
}

.content{
  position: relative;

  .el-icon-close{
    font-size: 16px;
    position: absolute;
    right: -4px;
    top: -6px;
  }
}

.wrapper{
  padding: 20px;
  background-color: #eef5ff;
  border-radius: 10px;
  margin-bottom: 15px;
}

.condition-group{
  position: relative;
  padding-right: 30px;
  margin-bottom: 15px;

  .el-icon-delete{
    position: absolute;
    top: 10px;
    right: 10px;
  }
}

:deep(.el-dialog__body){
  padding: 15px 20px 0;
  height: calc(100vh - 300px);
  overflow-y: auto;
}
</style>
